import React, { useState, useEffect } from 'react';
import {
  CardActions,
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  DateInput,
  LongTextInput,
  DateField,
  ReferenceInput,
  EditButton,
  CreateButton,
  ExportButton,
  RefreshButton,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput
} from 'react-admin';
import feathers from '../feathersClient';

const ResourceFilter = props => <Filter {...props} />;

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <RefreshButton />
  </CardActions>
);

export const ResourceList = props => (
  <List {...props} title={'Usuários'} actions={<ResourceActions />}>
    <Datagrid>
      <TextField source="email" label="email" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ResourceCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" label="Email" />
      <TextInput source="password" label="Senha" />
      <ReferenceInput
        label="Cliente"
        source="enterprise"
        reference="enterprises"
        fullWidth={true}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const ResourceEdit = props => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  useEffect(() => {
    console.log('props', props);
    getUser();
  }, []);
  const getUser = async () => {
    await feathers.passport.getJWT().then(async token => {
      const user = await feathers.passport.verifyJWT(token);
      if (props.permissions !== 'admin') {
        if (props.id !== user.userId) {
          props.history.push('/');
        }
      }
      setUser(user);
      setLoading(false);
    });
  };
  return (
    <>
      {loading && <div>Carregando...</div>}
      {!loading && (
        <Edit {...props}>
          <SimpleForm>
            <TextInput source="email" label="Email" />
            <TextInput source="password" label="Senha" />
          </SimpleForm>
        </Edit>
      )}
    </>
  );
};
