import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { crudGetOne, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

class MyUserMenuView extends Component {
  render() {
    const { crudGetOne, profile, ...props } = this.props;

    return (
      <UserMenu label="Perfil" {...props}>
        <MenuItemLink
          to={'/my-profile'}
          primaryText="Perfil"
          leftIcon={<SettingsIcon />}
        />
      </UserMenu>
    );
  }
}

const mapStateToProps = state => {
  const resource = 'users';
  const id = 'me';
  const profileState = state.admin.resources[resource];

  return {
    profile: profileState ? profileState.data[id] : null
  };
};

const MyUserMenu = connect(
  mapStateToProps,
  { crudGetOne }
)(MyUserMenuView);
export default MyUserMenu;
