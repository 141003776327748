import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
// import portugueseMessages from "ra-language-portuguese";
import { authClient, restClient } from 'ra-data-feathers';
import { createMuiTheme } from '@material-ui/core/styles';
import MyLayout from './MyLayout';
import portugueseMessages from 'ra-language-portuguese';
import profile from './profile';
import AddToHomescreen from 'react-add-to-homescreen';
import feathersClient from './feathersClient';
import {
  ResourceList as UserList,
  ResourceCreate as UserCreate,
  ResourceEdit as UserEdit
} from './models/users';
import {
  ResourceList as EnterprisesList,
  ResourceCreate as EnterprisesCreate,
  ResourceEdit as EnterprisesEdit
} from './models/enterprises';
import {
  ResourceList as PaymentMethodsList,
  ResourceCreate as PaymentMethodsCreate,
  ResourceEdit as PaymentMethodsEdit
} from './models/paymentMethods';
import {
  ResourceList as OrderList,
  ResourceCreate as OrderCreate,
  ResourceEdit as OrderEdit,
  ResourceShow as OrderShow
} from './models/orders';
import logo from './logo.svg';
import './App.css';
import uploadCap from './uploadCap';
import profileInt from './profileInt';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: será calculada com base em palette.primary.main,
      main: '#fabc33'
      // dark: será calculada com base em palette.primary.main,
      // contrastText: será calculada para contrastar com palette.primary.main
    }
    // secondary: {
    //   light: '#0066ff',
    //   main: '#0044ff',
    //   // dark: será calculada com base em palette.secondary.main,
    //   contrastText: '#ffcc00',
    // },
    // error: irá usar a cor padrão
  }
});

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { strategy: 'local' },
  permissionsField: 'roles'
};

// to rename id field for *all* resources use this syntax:
// const options = {  };
//

portugueseMessages['ra']['navigation']['page_rows_per_page'] =
  'Resultados por página';

const messages = {
  pt: portugueseMessages
};

const i18nProvider = locale => messages[locale];

const options = { id: '_id', usePatch: true };
const handleAddToHomescreenClick = () => {
  alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
};

class App extends Component {
  render() {
    return (
      <>
        {/* <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} /> */}
        <Admin
          title="OOBDIGITAL"
          theme={theme}
          locale="pt"
          i18nProvider={i18nProvider}
          authProvider={authClient(feathersClient, authClientOptions)}
          dataProvider={uploadCap(restClient(feathersClient, options))}
          appLayout={MyLayout}
          customRoutes={[
            <Route
              key="my-profile"
              path="/my-profile"
              component={profile.edit}
            />
          ]}
        >
          {permissions => [
            <Resource
              options={{ label: 'Serviços' }}
              title="OOB"
              name="orders"
              list={OrderList}
              create={permissions === 'admin' ? OrderCreate : null}
              edit={permissions === 'admin' ? OrderEdit : null}
              show={OrderShow}
            />,
            <Resource
              options={{ label: 'Usuários' }}
              name="users"
              list={permissions === 'admin' ? UserList : null}
              create={permissions === 'admin' ? UserCreate : null}
              edit={UserEdit}
            />,

            <Resource
              options={{ label: 'Métodos de pagamento' }}
              name="payment-methods"
              list={permissions === 'admin' ? PaymentMethodsList : null}
              create={permissions === 'admin' ? PaymentMethodsCreate : null}
              edit={permissions === 'admin' ? PaymentMethodsEdit : null}
            />,
            <Resource
              options={{ label: 'Empresas' }}
              name="enterprises"
              list={permissions === 'admin' ? EnterprisesList : null}
              create={permissions === 'admin' ? EnterprisesCreate : null}
              edit={permissions === 'admin' ? EnterprisesEdit : null}
            />
          ]}
        </Admin>
      </>
    );
  }
}

export default App;
