import axios from 'axios';
import feathers from './feathersClient';

const addUploadCapabilities = requestHandler => (type, resource, params) => {
  console.log('type', type);
  if ((type === 'CREATE' || type === 'UPDATE') && resource === 'orders') {
    let _payments = [];
    if (params.data.payments && params.data.payments.length) {
      return Promise.all(
        params.data.payments.map(payment => {
          return new Promise(async (resolve, reject) => {
            if (payment.files) {
              let data = new FormData();
              data.append('file', payment.files.rawFile);
              await feathers.passport.getJWT().then(async token => {
                console.log('token', token);
                await axios
                  .post('http://localhost:3030/app-uploads', data, {
                    headers: { Authorization: 'Bearer ' + token }
                  })
                  .then(response => {
                    _payments.push({ ...payment, file: response.data.path });
                    resolve();
                    // return response;
                  })
                  .catch(error => {
                    resolve();
                    // return error;
                  });
              });
            } else {
              _payments.push(payment);
              resolve();
            }
          });
        })
      ).then(() =>
        requestHandler(type, resource, {
          ...params,
          data: { ...params.data, payments: _payments }
        })
      );
    }
  }

  return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
