import React from 'react';
import {
  CardActions,
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Create,
  Edit,
  SimpleForm,
  DisabledInput,
  DateInput,
  LongTextInput,
  DateField,
  EditButton,
  CreateButton,
  ExportButton,
  RefreshButton,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  SelectInput
} from 'react-admin';

const ResourceFilter = props => <Filter {...props} />;

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
    <RefreshButton />
  </CardActions>
);

export const ResourceList = props => (
  <List {...props} title={'Empresas'} actions={<ResourceActions />}>
    <Datagrid>
      <TextField source="name" label="Nome" />
      <EditButton />
    </Datagrid>
  </List>
);

export const ResourceCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome" />
      <TextInput source="cnpj" label="Cnpj" />
    </SimpleForm>
  </Create>
);

export const ResourceEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" label="Nome" />
      <TextInput source="cnpj" label="Cnpj" />
    </SimpleForm>
  </Edit>
);
