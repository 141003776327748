import React from 'react';
import {
  CardActions,
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceManyField,
  Create,
  Edit,
  Show,
  TabbedShowLayout,
  Tab,
  ArrayField,
  SimpleForm,
  TabbedForm,
  FormTab,
  DisabledInput,
  DateInput,
  LongTextInput,
  DateField,
  EditButton,
  CreateButton,
  ExportButton,
  RefreshButton,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  FormDataConsumer,
  ShowButton
} from 'react-admin';

const defaultTitle = { title: 'OOBDIGITAL' };

List.defaultProps = Show.defaultProps = Edit.defaultProps = {
  title: 'OOBDIGITAL'
};

const ResourceFilter = props => <Filter {...props} />;

const onUpload = file => {
  console.log('file', file);
};

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  permissions
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button'
      })}

    {permissions === 'admin' ? <CreateButton basePath={basePath} /> : null}
    {permissions === 'admin' ? (
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    ) : null}
    <RefreshButton />
  </CardActions>
);

export const ResourceList = ({ permissions, ...props }) => (
  <List {...props} actions={<ResourceActions permissions={permissions} />}>
    <Datagrid selectable={false}>
      <TextField source="name" label="Serviço" />
      <ShowButton />
      {/* {permissions === 'admin' ? <EditButton /> : null} */}
    </Datagrid>
  </List>
);

export const ResourceCreate = props => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Dados">
        <SelectInput
          source="status"
          label="Status"
          choices={[{ id: '0', name: 'Inativo' }, { id: '1', name: 'Ativo' }]}
          defaultValue={'1'}
          fullWidth={true}
        />
        <ReferenceInput
          label="Cliente"
          source="payer"
          reference="enterprises"
          fullWidth={true}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" label="Nome" fullWidth={true} />
        <TextInput source="description" label="Descrição" fullWidth={true} />
        <NumberInput source="dayOfMonth" label="dia do mês" fullWidth={true} />
        <BooleanInput source="monthly" label="Mensal" fullWidth={true} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.monthly && (
              <NumberInput
                source="monthlyValueCents"
                label="Valor Mensal"
                format={v => v / 100}
                parse={v => v * 100}
                fullWidth={true}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.monthly && (
              <>
                <div>
                  <NumberInput
                    source="parcelsNumber"
                    label="Numero de parcelas"
                    fullWidth={true}
                  />
                </div>
                <div>
                  <NumberInput
                    source="valueCents"
                    format={v => v / 100}
                    parse={v => v * 100}
                    label="Valor Total"
                    fullWidth={true}
                  />
                </div>
              </>
            )
          }
        </FormDataConsumer>
        <ReferenceInput
          label="Método de pagamento"
          source="paymentMethod"
          reference="payment-methods"
        >
          <SelectInput optionText="name" fullWidth={true} />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Pagamentos">
        <ArrayInput source="payments" label="" fullWidth={true}>
          <SimpleFormIterator fullWidth={true}>
            <TextInput source="description" fullWidth={true} />
            <FileInput
              source="files"
              label="Boleto"
              accept="application/pdf"
              fullWidth={true}
            >
              <FileField source="src" title="title" />
            </FileInput>
            <NumberInput
              source="valueCents"
              format={v => v / 100}
              parse={v => v * 100}
              label="Valor"
              fullWidth={true}
            />
            <TextInput source="fiscal" label="Nota fiscal" fullWidth={true} />
            <SelectInput
              source="status"
              label="Status"
              choices={[
                { id: '0', name: 'Pendente' },
                { id: '1', name: 'Pago' }
              ]}
              defaultValue={'0'}
              fullWidth={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

export const ResourceEdit = props => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Dados">
        <SelectInput
          source="status"
          label="Status"
          choices={[{ id: '0', name: 'Inativo' }, { id: '1', name: 'Ativo' }]}
          defaultValue={'1'}
          fullWidth={true}
        />
        <ReferenceInput
          label="Cliente"
          source="payer"
          reference="enterprises"
          fullWidth={true}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" label="Nome" fullWidth={true} />
        <TextInput source="description" label="Descrição" fullWidth={true} />
        <NumberInput source="dayOfMonth" label="dia do mês" fullWidth={true} />
        <BooleanInput source="monthly" label="Mensal" fullWidth={true} />
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.monthly && (
              <NumberInput
                source="monthlyValueCents"
                label="Valor Mensal"
                format={v => v / 100}
                parse={v => v * 100}
                fullWidth={true}
              />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !formData.monthly && (
              <>
                <div>
                  <NumberInput
                    source="parcelsNumber"
                    label="Numero de parcelas"
                    fullWidth={true}
                  />
                </div>
                <div>
                  <NumberInput
                    source="valueCents"
                    format={v => v / 100}
                    parse={v => v * 100}
                    label="Valor Total"
                    fullWidth={true}
                  />
                </div>
              </>
            )
          }
        </FormDataConsumer>
        <ReferenceInput
          label="Método de pagamento"
          source="paymentMethod"
          reference="payment-methods"
        >
          <SelectInput optionText="name" fullWidth={true} />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Pagamentos">
        <ArrayInput source="payments" label="" fullWidth={true}>
          <SimpleFormIterator fullWidth={true}>
            <TextInput source="description" fullWidth={true} />
            <DateInput source="payDate" label="Vencimento" />
            <FileInput
              source="files"
              label="Boleto"
              accept="application/pdf"
              fullWidth={true}
            >
              <FileField source="src" title="title" />
            </FileInput>
            <NumberInput
              source="valueCents"
              format={v => v / 100}
              parse={v => v * 100}
              label="Valor"
              fullWidth={true}
            />
            <TextInput source="fiscal" label="Nota fiscal" fullWidth={true} />
            <SelectInput
              source="status"
              label="Status"
              choices={[
                { id: '0', name: 'Pendente' },
                { id: '1', name: 'Pago' }
              ]}
              defaultValue={'0'}
              fullWidth={true}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ResourceShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Dados">
        <TextField source="name" label="Serviço" />
        <TextField source="description" label="Descrição" />
        <TextField source="dayOfMonth" label="Dia do Vencimento" />
      </Tab>
      <Tab label="Pagamentos">
        <ArrayField source="payments" label="">
          <Datagrid>
            <TextField source="status" label="Status" />
            <TextField source="description" label="Descrição" />

            <DateField source="payDate" label="Vencimento" />
            <DateField source="payedAt" label="Pago em:" />
            <FileField
              source="file"
              label="Boleto"
              title="Ver"
              target="_blank"
            />
            <FileField
              source="fiscal"
              label="Nota Fiscal"
              title="Ver"
              target="_blank"
            />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
