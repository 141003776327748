import React, { useState, useEffect } from 'react';
import { Edit, TextInput, SimpleForm, required } from 'react-admin';
import feathers from '../feathersClient';

const ProfileEdit = ({ staticContext, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  useEffect(() => {
    getUser();
  }, []);
  const getUser = async () => {
    await feathers.passport.getJWT().then(async token => {
      const user = await feathers.passport.verifyJWT(token);
      console.log('user', user);
      setUser(user);
      setLoading(false);
    });
  };
  return (
    <>
      {loading && <div>Loading...</div>}
      {!loading && (
        <Edit
          redirect={false} // I don't need any redirection here, there's no list page
          {...props}
          id={user.userId}
          resource="users"
          basePath="/users"
          title="Meu Perfil"
        >
          <SimpleForm>
            <TextInput source="email" label="Email" />
            <TextInput source="password" label="Senha" />
          </SimpleForm>
        </Edit>
      )}
    </>
  );
};

export default ProfileEdit;
