import feathers from '@feathersjs/client';
import auth from '@feathersjs/authentication-client';

const host = 'https://cliente-api.oobdigital.com.br';
// const host = 'http://localhost:3090';
const authOptions = { jwtStrategy: 'jwt', storage: window.localStorage };

const feathersClient = feathers()
  .configure(feathers.rest(host).fetch(window.fetch.bind(window)))
  .configure(auth(authOptions));

export default feathersClient;
