import React, { useEffect, useState } from 'react';
import { AppBar } from 'react-admin';
import MyUserMenu from './MyUserMenu';

const MyAppBar = props => {
  const [showInstall, setShowInstall] = useState(false);
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', event => {
      console.log('👍', 'beforeinstallprompt', event);
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container
      setShowInstall(true);
    });
  }, []);
  const installPrompt = () => {
    console.log('👍', 'butInstall-clicked');
    const promptEvent = window.deferredPrompt;
    console.log('promptEvent', promptEvent);
    if (!promptEvent) {
      // The deferred prompt isn't available.
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    promptEvent.userChoice.then(result => {
      console.log('👍', 'userChoice', result);
      // Reset the deferred prompt variable, since
      // prompt() can only be called once.
      window.deferredPrompt = null;
      // Hide the install button.
      setShowInstall(false);
    });
  };
  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <div style={{ display: 'flex', flex: 1 }}>
        {showInstall && (
          <button type="button" onClick={() => installPrompt()}>
            Instalar
          </button>
        )}
      </div>
    </AppBar>
  );
};
export default MyAppBar;
